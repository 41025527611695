html {
  font-size: 62.5%;
  /* 62.5% of 16px = 10px */
}

html,
body {
  max-width: 100vw;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.d-flex-a-center {
  display: flex;
  align-items: center;
}

.d-flex-justify-sb {
  display: flex;
  justify-content: space-between;
}
